import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { DEFAULT_TENANT_ID } from '#config/api.config';
import { tenantApi, roleAndPermissionApi } from '#services/apis';

const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
  const auth = useAuth();
  const { revokeTokens, removeUser, signinPopup, signinRedirect, user } = auth;
  const { access_token } = user || {};
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  const [appUser, setAppUser] = useState();
  const [tenant, setTenant] = useState();
  const [serverError, setServerError] = useState();

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = auth.user.profile;
        user.tenantId = DEFAULT_TENANT_ID;
        user.accessToken = auth.user.access_token;
        user.permissions = await roleAndPermissionApi.getUserPermissions(
          user.tenantId,
        );
        const userRole = await roleAndPermissionApi.getRole(user.tenantId);
        const tenant = await tenantApi.getTenant(user.tenantId, false);
        setAppUser(user);
        setTenant(tenant);
      } catch (error) {
        setServerError(error);
        setAppUser(null);
      }
    };

    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      setAppUser(null);
      auth.signinRedirect();
      setHasTriedSignin(true);
    } else if (!auth.isLoading) {
      getUser();
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading]);

  const hasPermission = (permissionFqn) => {
    if (!appUser) {
      return false;
    }
    const userPermissions = appUser.permissions || [];
    const pIdx = userPermissions.findIndex((p) => p.fqn === permissionFqn);
    return pIdx > -1;
  };

  const hasAnyPermission = (permissionFqns = []) => {
    for (const fqn of permissionFqns) {
      if (hasPermission(fqn)) {
        return true;
      }
    }
    return false;
  };

  const userContext = {
    isLoading: auth.isLoading,
    isAuthenticated: auth.isAuthenticated,
    user: appUser,
    tenant,
    authError: auth.error,
    serverError,
    accessToken: access_token,
    hasPermission,
    hasAnyPermission,
    signinPopup: async () => {
      try {
        await removeUser();
      } catch (e) {
        console.log(e);
      }
      await signinPopup();
    },
    signinRedirect: async () => {
      try {
        await removeUser();
      } catch (e) {
        console.log(e);
      }
      await signinRedirect();
    },
    logout: async () => {
      if (auth.isAuthenticated) {
        try {
          await revokeTokens(['access_token', 'refresh_token']);
          await removeUser();
        } catch (e) {
          console.log(e);
        }
      }
    },
  };

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node,
};

function useUserContext() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }
  return context;
}

export { UserContextProvider, useUserContext };
