/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Grid,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormLabel,
  Typography,
  Button,
  CardActions,
  Divider,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import TextField from '#components/textField';
import SelectField from '#components/selectField';
import ProcessingParameters from '#components/processingParameters';
import { useUserContext } from '#contexts/userContext';
import { FILE_COLLECTIONS } from '#constants/uiPaths.constant';
import BooleanField from '#components/booleanField';
import EndpointParameters from './endpointParameters.component';
import { useToastContext } from '#contexts/providers/toast.provider';
import { dataSourceApi } from '#services/apis';

import {
  AWS_S3_PARAMS,
  AZURE_BLOB_PARAMS,
  MS_SITE_PARAMS,
} from './endpointParameters';

const DATA_SOURCE_TYPES = ['AWS_S3', 'AZURE_BLOB', 'MS_SITE'];

const DataSource = () => {
  const { user, hasPermission } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fileCollectionId } = useParams();
  const { dataSourceId } = useParams();
  const { pushToast } = useToastContext();
  const [isSaving, setIsSaving] = useState(false);

  const [editable, setEditable] = useState(
    hasPermission('qna:configure:fileCollection:dataSource:update'),
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState({
    name: '',
    description: '',
    endpointType: '',
    active: false,
  });
  const [prevValues, setPrevValues] = useState({});
  const [nameError, setNameError] = useState();
  const [endpointTypeError, setEndpointTypeError] = useState();
  const [hasChanged, setHasChanged] = useState(false);
  const [endpointParameters, setEndpointParameters] = useState([]);
  const [endpointParamErrors, setEndpointParamErrors] = useState({});

  useEffect(() => {
    const getDataSource = async () => {
      try {
        setLoading(true);
        const response = await dataSourceApi.getDataSource(
          user.tenantId,
          fileCollectionId,
          dataSourceId,
          true,
        );
        setDataSource(response);
        setPrevValues({ ...response });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.tenantId && fileCollectionId && dataSourceId) {
      getDataSource();
    }
  }, [user?.tenantId, fileCollectionId, dataSourceId]);

  useEffect(() => {
    let endpointParameters;
    switch (dataSource?.endpointType) {
      case 'AWS_S3':
        endpointParameters = AWS_S3_PARAMS;
        break;
      case 'AZURE_BLOB':
        endpointParameters = AZURE_BLOB_PARAMS;
        break;
      case 'MS_SITE':
        endpointParameters = MS_SITE_PARAMS;
        break;
      default:
        endpointParameters = [];
    }
    setEndpointParameters(endpointParameters);

    if (!dataSource?.id) {
      const parameters = {};
      if (!dataSource.id || !dataSource.endpointParameters) {
        for (let p of endpointParameters) {
          parameters[p.name] = p.defaultValue;
        }
      }
      setDataSource({
        ...dataSource,
        endpointParameters: parameters,
      });
    }
  }, [dataSource?.id, dataSource?.endpointType]);

  useEffect(() => {
    setEditable(
      hasPermission('qna:configure:fileCollection:dataSource:update'),
    );
  }, [user]);

  const onNameChange = (value) => {
    setDataSource((fc) => {
      return { ...fc, name: value };
    });
    setHasChanged(true);
  };

  const onDescriptionChange = (value) => {
    setDataSource((ds) => {
      return { ...ds, description: value };
    });
    setHasChanged(true);
  };

  const onEndpointTypeChange = (value) => {
    setDataSource({
      ...dataSource,
      endpointType: value,
    });
    setHasChanged(true);
  };

  const onActiveChange = (value) => {
    setDataSource((ds) => {
      return { ...ds, active: value };
    });
    setHasChanged(true);
  };

  const handleEndpointParametersChange = (name, value) => {
    const epParams = dataSource.endpointParameters || {};
    setDataSource((ds) => {
      return { ...ds, endpointParameters: { ...epParams, [name]: value } };
    });
    setHasChanged(true);
  };

  const handleCancelClick = () => {
    if (dataSourceId) {
      setDataSource(prevValues);
    } else {
      navigate(`${FILE_COLLECTIONS}/${fileCollectionId}`);
    }
    setNameError();
    setEndpointTypeError();
    setEndpointParamErrors({});
    setHasChanged(false);
  };

  const handleCloseClick = () => {
    navigate(`${FILE_COLLECTIONS}/${fileCollectionId}`);
  };

  const handleSaveClick = async () => {
    const errors = {};
    let hasError = false;
    setError();
    setNameError();
    if (!dataSource.name) {
      setNameError(t('components.dataSource.errors.nameRequired'));
      return;
    }
    if (!dataSource.endpointType) {
      setEndpointTypeError(
        t('components.dataSource.errors.endpointTypeRequired'),
      );
      return;
    }
    const {
      id,
      name,
      description,
      endpointType,
      active,
      endpointParameters: epValues = {},
    } = dataSource;
    endpointParameters.forEach((param) => {
      if (param.required && !epValues[param.name]) {
        errors[param.name] = t(
          'components.endpointParameters.errors.required',
          { field: t(`components.endpointParameters.fields.${param.name}`) },
        );
        hasError = true;
      }

      if (!epValues['delay'] && !epValues['schedulerCronExpression']) {
        errors['delay'] = t(
          'components.endpointParameters.errors.delayOrCronRequired',
        );
        errors['schedulerCronExpression'] = t(
          'components.endpointParameters.errors.delayOrCronRequired',
        );
        hasError = true;
      }
    });

    if (!hasError) {
      setEndpointParamErrors(errors);
      const epValuesArr = Object.entries(epValues).map(([name, value]) => ({
        name,
        value,
      }));
      setIsSaving(true);
      try {
        const ds = await dataSourceApi.saveDataSource(
          user.tenantId,
          fileCollectionId,
          {
            id,
            name,
            description,
            endpointType,
            active,
            endpointParameters: epValuesArr,
          },
        );
        pushToast({
          message: t('components.dataSource.toasts.saved'),
          severity: 'success',
        });
        if (!dataSourceId) {
          navigate(
            `${FILE_COLLECTIONS}/${fileCollectionId}/dataSources/${ds.id}`,
          );
        }
        setDataSource((dataSource) => {
          return { ...dataSource, ...ds };
        });
        setPrevValues((dataSource) => {
          return { ...dataSource, ...ds };
        });
        setHasChanged(false);
      } catch (error) {
        setError(error);
      }
      setIsSaving(false);
    } else {
      setEndpointParamErrors(errors);
    }
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '80vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container style={{ maxWidth: '95vw' }} spacing={1}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title={t('components.dataSource.header')}
            subheader={error && <Alert severity="error">{error.message}</Alert>}
            action={
              <ButtonGroup>
                <Button onClick={handleCloseClick} size="small" color="primary">
                  {t('components.dataSource.actions.close')}
                </Button>
              </ButtonGroup>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              {dataSource.id && (
                <Grid item xs={12} md={3}>
                  <FormControl variant="standard" fullWidth>
                    <FormLabel>{'Id'}</FormLabel>
                    <Typography>{dataSource.id}</Typography>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <TextField
                  label="Name"
                  required
                  onChange={onNameChange}
                  value={dataSource.name}
                  error={nameError}
                  disabled={!editable}
                />
              </Grid>
              {dataSource.createdDate && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  container
                  justifyContent={'flex-end'}
                  alignContent={'center'}
                >
                  <Typography>
                    {t('components.dataSource.fields.createdAt', {
                      time: format(
                        parseISO(dataSource.createdDate),
                        'do MMM yyyy h:mm:ss a',
                      ),
                    })}
                    <br />
                    {t('components.dataSource.fields.createdBy', {
                      name: dataSource.createdBy,
                    })}
                  </Typography>
                </Grid>
              )}
              {dataSource.lastUpdatedDate && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  container
                  justifyContent={'flex-end'}
                  alignContent={'center'}
                >
                  <Typography>
                    {t('components.dataSource.fields.lastUpdatedAt', {
                      time: format(
                        parseISO(dataSource?.lastUpdatedDate),
                        'do MMM yyyy h:mm:ss a',
                      ),
                    })}
                    <br />
                    {t('components.dataSource.fields.lastUpdatedBy', {
                      name: dataSource.lastUpdatedBy,
                    })}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  value={dataSource.description}
                  multiline
                  onChange={onDescriptionChange}
                  disabled={!editable}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SelectField
                  label={t('components.dataSource.fields.endpointType')}
                  value={dataSource.endpointType}
                  options={DATA_SOURCE_TYPES}
                  required
                  disabled={!editable || dataSource.id !== undefined}
                  error={endpointTypeError}
                  onChange={onEndpointTypeChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <BooleanField
                  label={t('components.dataSource.fields.active')}
                  value={dataSource?.active}
                  required
                  disabled={!editable}
                  onChange={onActiveChange}
                />
              </Grid>

              {dataSource?.endpointType && (
                <Grid item xs={12}>
                  <EndpointParameters
                    values={dataSource?.endpointParameters}
                    parameters={endpointParameters}
                    onChange={handleEndpointParametersChange}
                    errors={endpointParamErrors}
                    editable={editable}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
          {isSaving ? (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <CircularProgress />
            </CardActions>
          ) : (
            hasChanged && (
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button size="small" onClick={handleCancelClick}>
                  {t('components.dataSource.actions.cancel')}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={handleSaveClick}
                >
                  {t('components.dataSource.actions.save')}
                </Button>
              </CardActions>
            )
          )}
        </Card>
      </Grid>
      {dataSource?.id && (
        <Grid item xs={12}>
          <ProcessingParameters
            tenantId={user.tenantId}
            fileCollectionId={fileCollectionId}
            dataSourceId={dataSource.id}
            editable={editable}
            defaultValues={dataSource.processingParameters}
          />
        </Grid>
      )}
    </Grid>
  );
};

DataSource.propTypes = {
  fileCollectionId: PropTypes.string,
  dataSourceId: PropTypes.string,
  editable: PropTypes.bool,
};

export default DataSource;
