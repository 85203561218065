/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useUserContext } from '#contexts/userContext';
import {
  conversationApi,
  fileCollectionApi,
  roleAndPermissionApi,
} from '#services/apis';
import RightPanel from './rightPanel.component';
import LeftPanel from './leftPanel.component';
import ReferenceDocumentDialog from '#components/chatWindow/referenceDocumentDialog.component';
import { useToastContext } from '#contexts/providers/toast.provider';
import { useTranslation } from 'react-i18next';

const QnaPage = ({ appHeaderHeight }) => {
  const { user, hasPermission } = useUserContext();
  const { pushToast } = useToastContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMdScreenOrLarger = useMediaQuery(theme.breakpoints.up('md', true));

  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const [fileCollections, setFileCollectionns] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [qnaContext, setQnaContext] = useState({});
  const [qnaOptions, setQnaOptions] = useState({});
  const [viewOptions, setViewOptions] = useState({
    viewConversations: isMdScreenOrLarger && hasPermission('qna:conversation'),
    viewSearchOptions: isMdScreenOrLarger && hasPermission('qna:options'),
  });

  const [conversation, setConversation] = useState();

  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [referenceDocument, setReferenceDocument] = useState();

  useEffect(() => {
    const setUp = async () => {
      try {
        setLoading(true);
        const p1 = fileCollectionApi.getFileCollections(user.tenantId, false);

        const p2 = conversationApi.getUserConversations(user.tenantId);
        const restFc = roleAndPermissionApi.getUserRestrictedFileCollections(
          user.tenantId,
        );
        const [r1, r2, r3] = await Promise.allSettled([p1, p2, restFc]);
        if (r1.status == 'rejected') {
          throw new Error(r1.reason.message);
        }
        const filteredCollections = r1.value.filter(
          (fc) => !r3.value.includes(fc.id),
        );

        setFileCollectionns(filteredCollections);

        if (r2.status == 'rejected') {
          throw new Error(r2.reason.message);
        }
        setConversations(r2.value);
        setQnaOptions({
          llm: 'azureopenai',
          conversationMode: hasPermission('qna:conversation'),
          streamMode: true,
        });

        let conversation;
        if (r2.value && r2.value.length > 0) {
          conversation = r2.value[0];
        }
        let fileCollectionName;
        let searchContext = 'aboutAnything';
        if (conversation?.fileCollectionId) {
          const fileCollection = r1.value.find(
            (fc) => fc.id === conversation.fileCollectionId,
          );
          fileCollectionName = fileCollection?.name;
          searchContext = 'aboutFileCollection';
        }
        setConversation(conversation);
        setQnaContext({
          searchContext,
          fileCollectionId: conversation?.fileCollectionId,
          fileCollectionName,
          fileId: '',
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (user?.tenantId) {
      setUp();
    }
  }, [user]);

  const onQnaContextChange = async (context) => {
    setLoading(true);
    setQnaContext(context);
    let conversation;
    if (context.searchContext === 'aboutAnything') {
      conversation = conversations.find((c) => !c.fileCollectionId);
      if (!conversation) {
        conversation = await conversationApi.createConversation(user.tenantId);
        setConversations([conversation, ...conversations]);
      }
    } else if (
      context?.searchContext === 'aboutFileCollection' &&
      context.fileCollectionId
    ) {
      conversation = conversations.find(
        (c) => c.fileCollectionId === context.fileCollectionId,
      );
      if (!conversation) {
        conversation = await conversationApi.createConversation(
          user.tenantId,
          context.fileCollectionId,
        );
        setConversations([conversation, ...conversations]);
      }
    }
    setConversation(conversation);
    setLoading(false);
  };

  const onConversationChange = (conversation) => {
    setConversation(conversation);
    let fileCollectionName;
    let searchContext = 'aboutAnything';
    if (conversation.fileCollectionId) {
      const fileCollection = fileCollections.find(
        (fc) => fc.id === conversation.fileCollectionId,
      );
      fileCollectionName = fileCollection?.name;
      searchContext = 'aboutFileCollection';
    }
    setQnaContext({
      ...qnaContext,
      searchContext,
      fileCollectionId: conversation.fileCollectionId,
      fileCollectionName,
      fileId: '',
    });
  };

  const onQnaOptionsChange = (options) => {
    setQnaOptions(options);
  };

  const onViewOptionsChange = (options) => {
    setViewOptions(options);
  };

  const handleReferenceDocumentClick = (referenceDocument) => {
    setReferenceDocument(referenceDocument);
    setOpenDocumentDialog(true);
  };

  const closeDocumentDialog = () => {
    setOpenDocumentDialog(false);
  };

  const deleteConversation = async (c) => {
    try {
      setLoading(true);
      await conversationApi.deleteConversation(user.tenantId, c.id);
      const conversations = await conversationApi.getUserConversations(
        user.tenantId,
      );

      setConversations(conversations);

      if (conversation?.id === c.id) {
        let conversation;
        if (conversations.length > 0) {
          conversation = conversations[0];
        }
        let fileCollectionName;
        let searchContext = 'aboutAnything';
        if (conversation?.fileCollectionId) {
          const fileCollection = fileCollections.find(
            (fc) => fc.id === conversation.fileCollectionId,
          );
          fileCollectionName = fileCollection?.name;
          searchContext = 'aboutFileCollection';
        }
        setConversation(conversation);
        setQnaContext({
          searchContext,
          fileCollectionId: conversation?.fileCollectionId,
          fileCollectionName,
          fileId: '',
        });
      }

      pushToast({
        message: t('pages.qna.toasts.conversationDeleted'),
        severity: 'success',
      });
      setError();
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  const onNewConversation = async (conversationId) => {
    const conversations = await conversationApi.getUserConversations(
      user.tenantId,
    );

    setConversations(conversations);
    setConversation(conversations.find((c) => c.id === conversationId));
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '80vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Stack
      sx={{ height: '100%' }}
      justifyContent={'center'}
      direction={'row'}
      alignItems={'flex-start'}
      spacing={1}
    >
      <LeftPanel
        appHeaderHeight={appHeaderHeight}
        fileCollections={fileCollections}
        qnaContext={qnaContext}
        qnaOptions={qnaOptions}
        viewOptions={viewOptions}
        conversation={conversation}
        onNewConversation={onNewConversation}
        onQnaContextChange={onQnaContextChange}
        onViewOptionsChange={onViewOptionsChange}
        onReferenceDocumentClick={handleReferenceDocumentClick}
        onError={setError}
        error={error}
      />
      {(viewOptions.viewConversations || viewOptions.viewSearchOptions) && (
        <RightPanel
          appHeaderHeight={appHeaderHeight}
          qnaOptions={qnaOptions}
          viewOptions={viewOptions}
          conversation={conversation}
          conversations={conversations}
          onConversationChange={onConversationChange}
          onQnAOptionsChange={onQnaOptionsChange}
          onViewOptionsChange={onViewOptionsChange}
          onDeleteConversation={deleteConversation}
        />
      )}
      {openDocumentDialog && (
        <ReferenceDocumentDialog
          referenceDocument={referenceDocument}
          open={openDocumentDialog}
          onClose={closeDocumentDialog}
        />
      )}
    </Stack>
  );
};

QnaPage.propTypes = {
  appHeaderHeight: PropTypes.number,
};

export default QnaPage;
