/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '#contexts/userContext';
import userAuthApis from '#services/apis/userAuth.api';
import SelectUsers from './selectUsersDialog.component';
import ConfirmationDialog from '#components/confirmationDialog';
import DataGridTable from '#components/datagridTable';
import { cacheApi } from '#services/apis';

const RoleUsers = ({ roleId, defaultValues = [], editable }) => {
  const { t } = useTranslation();
  const { user, hasPermission } = useUserContext();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isAddUserDialogOpen, setAddUserDialogOpen] = useState(false);

  const [roleUsers, setRoleUsers] = useState(defaultValues);
  const [selectedUser, setSelectedUser] = useState();
  const [loadUsers, setLoadUsers] = useState(true);

  useEffect(() => {
    const getUserRoles = async () => {
      try {
        setLoading(true);
        const response = await userAuthApis.getUsersForRole(
          user.tenantId,
          roleId,
        );
        setRoleUsers(
          response.map((ur) => {
            const { userId, createdDate, createdBy } = ur;
            return { id: userId, userId, createdDate, createdBy };
          }),
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
        setLoadUsers(false);
      }
    };
    if ((user?.tenantId && roleId, loadUsers)) {
      getUserRoles();
    }
  }, [user?.tenantId, roleId, loadUsers]);

  const handleOpenAddUserDialog = () => {
    setAddUserDialogOpen(true);
  };

  const handleOpenDeleteDialog = (user) => {
    setSelectedUser(user.userId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteUser = async () => {
    try {
      setLoading(true);
      await userAuthApis.deleteUserRole(user.tenantId, roleId, selectedUser);
      await cacheApi.clearCache(user?.tenantId, ['ROLES']);
      setLoadUsers(true);
      setSelectedUser();
      setError();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleCloseAddUserDialog = () => {
    setAddUserDialogOpen(false);
  };

  const addUsers = async (selectedUsers) => {
    try {
      const usersToAdd = [];
      for (let user of selectedUsers) {
        const idx = roleUsers.findIndex((u) => u.userId === user);
        if (idx < 0) {
          usersToAdd.push(user);
        }
      }
      if (usersToAdd.length > 0) {
        await userAuthApis.addUsersToRole(user.tenantId, roleId, usersToAdd);
        await cacheApi.clearCache(user?.tenantId, ['ROLES']);
        setLoadUsers(true);
      }
      setError();
      handleCloseAddUserDialog();
    } catch (error) {
      setError(error);
    }
  };

  const columns = [
    {
      field: 'userId',
      headerName: t('components.roleUsers.columns.userId'),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'createdBy',
      headerName: t('components.roleUsers.columns.createdBy'),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'createdDate',
      headerName: t('components.roleUsers.columns.createdDate'),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'actions',
      headerName: t('components.dataSources.columns.actions'),
      headerAlign: 'center',
      width: 120,
      align: 'center',
      sortable: false,
      renderCell: (props) =>
        editable && hasPermission('system:configure:roles:users:delete') ? (
          <Tooltip title="Delete" arrow>
            <IconButton
              onClick={() => handleOpenDeleteDialog(props.row)}
              color="error"
              disableRipple
            >
              <Delete fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <DataGridTable
        rows={roleUsers}
        columns={columns}
        header={t('components.roleUsers.header')}
        onRowDoubleClick={() => {}}
        allowNewRecord={
          editable && hasPermission('system:configure:roles:users:add')
        }
        newRecordLabel={'Add'}
        onNewRecord={handleOpenAddUserDialog}
        error={error}
      ></DataGridTable>
      <SelectUsers
        open={isAddUserDialogOpen}
        onSubmit={addUsers}
        onCancel={handleCloseAddUserDialog}
      />
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title={t('components.roleUsers.actions.delete.title')}
        messageLine1={t('components.roleUsers.actions.delete.messageLine1', {
          itemName: selectedUser,
        })}
        messageLine2={t('components.roleUsers.actions.delete.messageLine2')}
        onYesClick={handleDeleteUser}
        onNoClick={handleCloseDeleteDialog}
      />
    </Box>
  );
};

RoleUsers.propTypes = {
  roleId: PropTypes.string,
  defaultValues: PropTypes.array,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  row: PropTypes.any,
};

export default RoleUsers;
