/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useUserContext } from '#contexts/userContext';
import { roleAndPermissionApi } from '#services/apis';
import { ROLES } from '#constants/uiPaths.constant';
import TextField from '#components/textField';
import RolePermissions from './rolePermissions.component';
import { useToastContext } from '#contexts/providers/toast.provider';
import RoleUsers from './roleUsers.component';
import RoleFileCollections from './roleFileCollections.component';

const Role = ({ roleId }) => {
  const { user, hasPermission } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pushToast } = useToastContext();

  const [editable, setEditable] = useState(
    hasPermission('system:configure:roles:update'),
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [role, setRole] = useState({
    name: '',
    description: '',
  });
  const [prevValues, setPrevValues] = useState({});
  const [nameError, setNameError] = useState();
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    const getRole = async () => {
      try {
        setLoading(true);
        const response = await roleAndPermissionApi.getRole(
          user.tenantId,
          roleId,
          true,
        );
        setRole(response);
        setPrevValues({ ...response });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.tenantId && roleId) {
      getRole();
    }
  }, [user?.tenantId, roleId]);

  const onNameChange = (value) => {
    setRole((r) => {
      return { ...r, name: value };
    });
    setHasChanged(true);
  };

  const onDescriptionChange = (value) => {
    setRole((r) => {
      return { ...r, description: value };
    });
    setHasChanged(true);
  };

  const handleCancelClick = () => {
    if (role.id) {
      setRole(prevValues);
    } else {
      navigate(ROLES);
    }
    setHasChanged(false);
  };

  const handleCloseClick = () => {
    navigate(ROLES);
  };

  const handleSaveClick = async () => {
    setError();
    setNameError();
    if (!role.name) {
      setNameError(t('components.role.errors.nameRequired'));
      return;
    }
    const { id, name, description } = role;
    setIsSaving(true);
    try {
      const role = await roleAndPermissionApi.saveRole(user.tenantId, {
        id,
        name,
        description,
      });
      pushToast({
        message: t('components.role.toasts.saved'),
        severity: 'success',
      });
      if (!roleId) {
        navigate(`${ROLES}/${role.id}`);
      }
      setRole(role);
      setPrevValues({ ...role });
      setHasChanged(false);
    } catch (error) {
      setError(error);
    }
    setIsSaving(false);
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '80vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container style={{ maxWidth: '95vw' }}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title={
              <Typography variant="h3">
                {t('components.role.header')}
              </Typography>
            }
            subheader={error && <Alert severity="error">{error.message}</Alert>}
            action={
              <ButtonGroup>
                <Button onClick={handleCloseClick} size="small" color="primary">
                  {t('components.role.actions.close')}
                </Button>
              </ButtonGroup>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              {role?.id && (
                <Grid item xs={12} md={3}>
                  <FormControl variant="standard" fullWidth>
                    <FormLabel>{t('components.role.fields.id')}</FormLabel>
                    <Typography>{role?.id}</Typography>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <TextField
                  label={t('components.role.fields.name')}
                  required
                  onChange={onNameChange}
                  value={role?.name}
                  disabled={!editable}
                  error={nameError}
                />
              </Grid>
              {role?.createdDate && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  container
                  justifyContent={'flex-end'}
                  alignContent={'center'}
                >
                  <Typography>
                    {t('components.role.fields.createdAt', {
                      time: format(
                        parseISO(role?.createdDate),
                        'do MMM yyyy h:mm:ss a',
                      ),
                    })}
                    <br />
                    {t('components.role.fields.createdBy', {
                      name: role?.createdBy,
                    })}
                  </Typography>
                </Grid>
              )}
              {role?.lastUpdatedDate && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  container
                  justifyContent={'flex-end'}
                  alignContent={'center'}
                >
                  <Typography>
                    {t('components.role.fields.lastUpdatedAt', {
                      time: format(
                        parseISO(role?.lastUpdatedDate),
                        'do MMM yyyy h:mm:ss a',
                      ),
                    })}
                    <br />
                    {t('components.role.fields.lastUpdatedBy', {
                      name: role?.lastUpdatedBy,
                    })}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label={t('components.role.fields.description')}
                  value={role?.description}
                  multiline
                  onChange={onDescriptionChange}
                  disabled={!editable}
                />
              </Grid>
            </Grid>
          </CardContent>
          {isSaving ? (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <CircularProgress />
            </CardActions>
          ) : (
            editable &&
            hasChanged && (
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button size="small" onClick={handleCancelClick}>
                  {t('components.role.actions.cancel')}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={handleSaveClick}
                >
                  {t('components.role.actions.save')}
                </Button>
              </CardActions>
            )
          )}
        </Card>
      </Grid>
      {role?.id && (
        <Grid item xs={12}>
          <RolePermissions
            roleId={role?.id}
            defaultValues={role?.permissions}
            editable={editable}
          />
        </Grid>
      )}
      {role?.id && (
        <Grid item xs={12}>
          <RoleFileCollections
            role={role}
            onChange={setRole}
            editable={editable}
          />
        </Grid>
      )}
      {role?.id && hasPermission('system:configure:roles:users') && (
        <Grid item container xs={12}>
          <RoleUsers
            roleId={roleId}
            defaultValues={role?.users}
            editable={editable}
          />
        </Grid>
      )}
    </Grid>
  );
};

Role.propTypes = {
  roleId: PropTypes.string,
};

export default Role;
