/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Grid,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormLabel,
  Typography,
  Button,
  CardActions,
  Divider,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import TextField from '#components/textField';
import ProcessingParameters from '#components/processingParameters';
import SearchParameters from '#components/searchParameters';
import { useUserContext } from '#contexts/userContext';
import { FILE_COLLECTIONS } from '#constants/uiPaths.constant';
import DataSources from './dataSources.component';
import { useToastContext } from '#contexts/providers/toast.provider';
import { fileCollectionApi } from '#services/apis';

const FileCollection = ({ fileCollectionId }) => {
  const { user, hasPermission } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pushToast } = useToastContext();

  const [editable, setEditable] = useState(
    hasPermission('qna:configure:fileCollection:update'),
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fileCollection, setFileCollection] = useState({
    name: '',
    description: '',
  });
  const [prevValues, setPrevValues] = useState({});
  const [nameError, setNameError] = useState();
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    const getFileCollection = async () => {
      try {
        setLoading(true);
        const response = await fileCollectionApi.getFileCollection(
          user.tenantId,
          fileCollectionId,
          true,
        );
        setFileCollection(response);
        setPrevValues({ ...response });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.tenantId && fileCollectionId) {
      getFileCollection();
    }
  }, [user?.tenantId, fileCollectionId]);

  useEffect(() => {
    setEditable(hasPermission('qna:configure:fileCollection:update'));
  }, [user]);

  const onNameChange = (value) => {
    setFileCollection((fc) => {
      return { ...fc, name: value };
    });
    setHasChanged(true);
  };

  const onDescriptionChange = (value) => {
    setFileCollection((fc) => {
      return { ...fc, description: value };
    });
    setHasChanged(true);
  };

  const handleCancelClick = () => {
    if (fileCollection.id) {
      setFileCollection(prevValues);
    } else {
      navigate(FILE_COLLECTIONS);
    }
    setHasChanged(false);
  };

  const handleCloseClick = () => {
    navigate(FILE_COLLECTIONS);
  };

  const handleSaveClick = async () => {
    setError();
    setNameError();
    if (!fileCollection.name) {
      setNameError(t('components.fileCollection.errors.nameRequired'));
      return;
    }
    const { id, name, description } = fileCollection;
    setIsSaving(true);
    try {
      const fc = await fileCollectionApi.saveFileCollection(user.tenantId, {
        id,
        name,
        description,
      });
      pushToast({
        message: t('components.fileCollection.toasts.saved'),
        severity: 'success',
      });
      if (!fileCollectionId) {
        navigate(`${FILE_COLLECTIONS}/${fc.id}`);
      }
      setFileCollection(fc);
      setPrevValues({ ...fc });
      setHasChanged(false);
    } catch (error) {
      setError(error);
    }
    setIsSaving(false);
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '80vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container style={{ maxWidth: '95vw' }}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title={t('components.fileCollection.header')}
            subheader={error && <Alert severity="error">{error.message}</Alert>}
            action={
              <ButtonGroup>
                <Button onClick={handleCloseClick} size="small" color="primary">
                  {t('components.fileCollection.actions.close')}
                </Button>
              </ButtonGroup>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              {fileCollection.id && (
                <Grid item xs={12} md={3}>
                  <FormControl variant="standard" fullWidth>
                    <FormLabel>
                      {t('components.fileCollection.fields.id')}
                    </FormLabel>
                    <Typography>{fileCollection.id}</Typography>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <TextField
                  label={t('components.fileCollection.fields.name')}
                  required
                  onChange={onNameChange}
                  value={fileCollection.name}
                  disabled={!editable}
                  error={nameError}
                />
              </Grid>
              {fileCollection.createdDate && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  container
                  justifyContent={'flex-end'}
                  alignContent={'center'}
                >
                  <Typography>
                    {t('components.fileCollection.fields.createdAt', {
                      time: format(
                        parseISO(fileCollection?.createdDate),
                        'do MMM yyyy h:mm:ss a',
                      ),
                    })}
                    <br />
                    {t('components.fileCollection.fields.createdBy', {
                      name: fileCollection.createdBy,
                    })}
                  </Typography>
                </Grid>
              )}
              {fileCollection.lastUpdatedDate && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  container
                  justifyContent={'flex-end'}
                  alignContent={'center'}
                >
                  <Typography>
                    {t('components.fileCollection.fields.lastUpdatedAt', {
                      time: format(
                        parseISO(fileCollection?.lastUpdatedDate),
                        'do MMM yyyy h:mm:ss a',
                      ),
                    })}
                    <br />
                    {t('components.fileCollection.fields.lastUpdatedBy', {
                      name: fileCollection.lastUpdatedBy,
                    })}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label={t('components.fileCollection.fields.description')}
                  value={fileCollection.description}
                  multiline
                  onChange={onDescriptionChange}
                  disabled={!editable}
                />
              </Grid>
            </Grid>
          </CardContent>
          {isSaving ? (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <CircularProgress />
            </CardActions>
          ) : (
            editable &&
            hasChanged && (
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button size="small" onClick={handleCancelClick}>
                  {t('components.fileCollection.actions.cancel')}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={handleSaveClick}
                >
                  {t('components.fileCollection.actions.save')}
                </Button>
              </CardActions>
            )
          )}
        </Card>
      </Grid>
      {fileCollection?.id && (
        <Grid item xs={12}>
          <ProcessingParameters
            tenantId={user.tenantId}
            fileCollectionId={fileCollection.id}
            editable={hasPermission('qna:configure:fileCollection:options')}
            defaultValues={fileCollection.processingParameters}
          />
        </Grid>
      )}
      {fileCollection?.id && (
        <Grid item xs={12}>
          <SearchParameters
            tenantId={user.tenantId}
            fileCollectionId={fileCollection.id}
            editable={hasPermission('qna:configure:fileCollection:options')}
            defaultValues={fileCollection.searchParameters}
          />
        </Grid>
      )}
      {fileCollection?.id &&
        hasPermission('qna:configure:fileCollection:dataSource') && (
          <Grid item container xs={12}>
            <DataSources fileCollectionId={fileCollection?.id} />
          </Grid>
        )}
    </Grid>
  );
};

FileCollection.propTypes = {
  fileCollectionId: PropTypes.string,
};

export default FileCollection;
